<template>
    <div>
        <app-view-table :isTopBar="false" :isRowHover="true" :filters.sync="filters" :items="items" v-on:onRowSelected="goToAssignmentProfile" :fields="fields" :customColumns="customColumns" :customColumnsHeaders="customHeaders" class="table-selectable" :key="refresh">
           
           <template v-slot:head_selected>
                <div @click.stop class="h-100 d-flex align-items-center justify-content-center">
                    <b-form-group class="pl-2">
                        <b-form-checkbox v-model="selected_all_rows" class="custom-control-primary"  @change="selectAll()" style="margin-top:5px" size="md" :class="selected_all_rows ? 'custom-checkbox-checked' : ''"/>
                    </b-form-group>
                </div>
            </template>

            <template v-slot:selected="cell">
                <div @click.stop class="h-100 d-flex align-items-center justify-content-center">
                    <b-form-group class="pl-2">
                        <b-form-checkbox v-model="cell.data.item.selected" :key="refreshCheck" @change="select($event)" class="custom-control-primary" style="margin-top:5px" size="md" :class="cell.data.item.selected ? 'custom-checkbox-checked' : ''"/>
                    </b-form-group>
                </div>
            </template>

            <template v-slot:menu-items="cell">
                <b-dropdown-item v-if="[1, 8].includes(cell.data.item.id_assignment_const_status) && (user_role < 3 || cell.data.item.can_user_edit)" @click="addPerson(cell.data.item)">{{trans('cp-register-flexworker',189)}}</b-dropdown-item>    
                <b-dropdown-item v-if="![6,3,5,7].includes(cell.data.item.id_assignment_const_status) && (user_role < 3 || cell.data.item.can_user_edit)" @click="cancelAssignment(cell.data.item)">{{trans('cancel-posting',175)}}</b-dropdown-item>
                <b-dropdown-divider v-if="([1, 8].includes(cell.data.item.id_assignment_const_status) || ![6,3,5,7].includes(cell.data.item.id_assignment_const_status) ) && (user_role < 3 || cell.data.item.can_user_edit)"></b-dropdown-divider>              
                <b-dropdown-item v-if="cell.data.item.id_person_data_main" :to="{ name: 'people-person-profile', params: { id_person_data_main: cell.data.item.id_person_data_main }}"> {{trans('to-flexworker',175)}}</b-dropdown-item>
                <b-dropdown-item :to="{ name: 'clients-profile-organization', params: { id_client_data_main: cell.data.item.id_client_data_main }}">{{trans('to-organization',175)}}</b-dropdown-item>
                <b-dropdown-item :to="{ name: 'clients-profile-location', params: { id_location_data_main: cell.data.item.id_location_data_main }}">{{trans('to-location',200)}}</b-dropdown-item>              
            </template>

            <template v-slot:buttons>

                <b-dropdown v-if="checked_items > 0" no-caret toggle-class="app-button-multi mr-2" variant="secondary-grey">
                    <template #button-content>
                         {{trans('action',3)}} ({{checked_items}})                         
                    </template>

                    <b-dropdown-item v-if="multi_menu_add_person_visible" @click="addPerson()">{{trans('cp-register-flexworker',189)}}</b-dropdown-item>   
                    <b-dropdown-item v-if="multi_menu_cancel"  @click="cancelAssignment(null)">{{trans('cancel-posting',175)}}</b-dropdown-item>      
                </b-dropdown>

                <app-button type="primary" :url="{ name: 'planning-offers-assignments-add'}">{{trans('add-posting',175)}}</app-button>
                <app-button type="primary" :loading="loading_download" @click="downloadList">{{trans('planning-export-assignments', 218)}}</app-button>
            </template>

        </app-view-table>
      
    </div>  
</template>
<script>


import axios from "@axios";
import AppAlerts from '@core/scripts/AppAlerts';
import UserSettings from '@core/scripts/UserSettings';
import moment from "moment";

  export default {

    props: ["loading", "offerDate", "offerWeek", "dateType", "total", "refresh", "listType", "listTypeChanged"],
    
    components: {
    },

    mounted() {
      this.onCreateThis();
    },

    computed: {
        cOfferDate: {
            get() { return this.offerDate },
            set(value) {this.$emit('update:offerDate', value) }
        },
        cOfferWeek: {
            get() { return this.offerWeek },
            set(value) {this.$emit('update:offerWeek', value) }
        },
        cDateType: {
            get() { return this.dateType },
            set(value) {this.$emit('update:dateType', value) }
        },
        cRefresh: {
            get() { return this.refresh },
            set(value) {this.$emit('update:refresh', value) }
        },
        cLoading: {
            get() { return this.loading },
            set(value) {this.$emit('update:loading', value) }
        },
        cListType: {
            get() { return this.listType },
            set(value) {this.$emit('update:listType', value) }
        },
        cListTypeChanged: {
            get() { return this.listTypeChanged },
            set(value) {this.$emit('update:listTypeChanged', value) }
        },
        
        cTotal: {
            get() { return this.total },
            set(value) {this.$emit('update:total', value) }
        },
    },

    data() {
        return {
            customColumns: ["selected"],
            customHeaders: ['selected'],
            filters: {
                sorting: {
                        field_name: null,
                        sort_direction: 0,
                        changed: 0
                },
                filtering: {                        
                    client_names: { type: 'list', changed: 0, values: [], function: "getClients", parameters: null, widthClass:'app-width-min-350'},
                    client_locations: { type: 'list', changed: 0, values: [], function: "getClientLocations", parameters: null, widthClass:'app-width-min-350'},
                    client_departments: { type: 'list', changed: 0, values: [], function: "getClientDepartments", parameters: null, widthClass:'app-width-min-350'},
                    functions: { type: 'list', changed: 0, values: [], function: "getFunctions", parameters: null, widthClass:'app-width-min-350'},
                    dates: { type: 'dates', changed: 0, values: [],  function: "", parameters: {type: null}, widthClass:'app-width-min-350'},
                    locations: { type: 'list', changed: 0, values: [], function: "getLocations", parameters: null, widthClass:'app-width-min-350'},                   
                    assignment_statuses: { type: 'list', changed: 0, values: [], function: "getAssignmentStatuses", parameters: null, widthClass:'app-width-min-350'},
                    shift_statuses: { type: 'list', changed: 0, values: [], function: "getShiftStatuses", parameters: null, widthClass:'app-width-min-350'},
                    persons: {type: 'list', changed: 0, values: [], function: 'getFlexworkers', parameters: null, widthClass:'app-width-min-250'}

                },
                page: 1,
                per_page: 10,
                search: "",                
                changed: 0,
                list_type:this.listType,
                date_type: this.dateType,
                offer_date: this.offerDate,
                offer_week: this.offerWeek,

            },
            fields: [
                { key: "selected", label: "",tdClass:"p-0", thClass:"p-0", visible: true  },
                { key: "client_name", label: this.trans('organization',169), thStyle: { "min-width": "150px", "max-width": "150px"  }, visible: true, filtering: true, filtering_name: "client_names"},
                { key: "client_location_name", label: this.trans('location',3), thStyle: { "min-width": "150px", "max-width": "150px" }, visible: true, filtering: true, filtering_name: "client_locations"},
                { key: "department_names", label: this.trans('department',182), thStyle: { "width": "100%" }, visible: true, filtering: true, filtering_name: "client_departments"},
                { key: "function_name", label: this.trans('function',3), thStyle: { "min-width": "150px", "max-width": "150px" }, visible: true, filtering: true, filtering_name: "functions"},
                { key: "location_name", label: this.trans('branch',182), thStyle: { "min-width": "150px", "max-width": "150px" }, visible: true, filtering: true, filtering_name: "locations"},
                { key: "date_from",  label: this.trans('date-start',3), thStyle: { "min-width": "130px", "max-width": "130px" }, visible: true, filtering: false},
                { key: "date_to",  label: this.trans('date-end',3), thStyle: { "min-width": "130px", "max-width": "130px" }, visible: true, filtering: false},
                { key: "person_name", label: this.trans('flexworker',182), thStyle: { "min-width": "150px", "max-width": "150px"}, visible: true, filtering: true, filtering_name: "persons"},              
                { key: "status_name", label: this.trans('status',3), thStyle: { "min-width": "120px", "max-width": "120px" }, visible: true, filtering: true, filtering_name: "assignment_statuses"},
            ],
            items: [],
            alertClass: new AppAlerts(),
            userSettings: new UserSettings(),
            checked_items: 0,
            refreshCheck: 0,
            selected_all_rows: false,
            user_role: JSON.parse(localStorage.getItem('user')).id_user_const_role,
            loading_download: false,
            multi_menu_add_person_visible: false,
            multi_menu_cancel: false
        };
    },

    methods: {
        getList() {
           this.cLoading = true;
           axios
                .post("planning/offers/getAssignments", {
                    filters: JSON.stringify(this.filters),
                })
                .then((res) => {                    
                    this.filters.page = res.data.page;
                    this.items = res.data.items;
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                })
                .finally(() => {
                    this.unselect();
                    this.cLoading = false;
                    this.cTotal = this.items.total;
                });
        },

        goToAssignmentProfile: function(selectedRows){
            if(selectedRows.length != 0){
                this.$router.push({ name: "planning-assignments-profile", params: { id_assignment_data_main: selectedRows[0].id } });
            }
        },

        selectAll(){
            
            if(this.selected_all_rows){
                this.items.list.forEach(element => {
                    element.selected = true;
                });
            } else {
                this.items.list.forEach(element => {
                    element.selected = false;
                });
                this.checked_items = 0;
            }

            this.select();

            this.refreshCheck++;

        },

        select(){
            if (this.items != undefined && this.items.list != undefined) {

                let selected = this.items.list.filter(function(value) { 
                    return value.selected == true; 
                }).length;
                
                if(this.items.list.length == selected){
                    this.selected_all_rows = true;
                }else{
                    this.selected_all_rows = false;
                }

                var userRole = this.user_role;
                let selected_to_add = this.items.list.filter(function(value) { 
                    return value.selected == true && (userRole < 3 || value.can_user_edit) && ![6,3,5,7].includes(value.id_assignment_const_status); 
                }).length;

                this.multiMenuVisible();
                this.checked_items = selected_to_add;
            }
        },

        multiMenuVisible(){
            if (this.items != undefined && this.items.list != undefined) {
                var userRole = this.user_role;

                let selected_to_add = this.items.list.filter(function(value) { 
                    return value.selected == true && [1, 8].includes(value.id_assignment_const_status) && ( userRole < 3 || value.can_user_edit); 
                }).length;

                let selected_cancel = this.items.list.filter(function(value) { 
                    return value.selected == true && ( userRole < 3 || value.can_user_edit) && ![6,3,5,7].includes(value.id_assignment_const_status); 
                }).length;

                let selected_all = this.items.list.filter(function(value) { 
                    return value.selected == true; 
                }).length;

                this.multi_menu_add_person_visible = selected_to_add > 0;
                this.multi_menu_cancel = selected_cancel > 0;

            }else{
                this.multi_menu_add_person_visible = false;
                this.multi_menu_cancel = false;
            }
            
            if(this.multi_menu_cancel == false && this.multi_menu_add_person_visible == false){
                this.cheked_items = 0;
            }
        },

        unselect(){
            this.checked_items = 0;
            this.selected_all_rows = false;
            this.items.list.forEach(element => {
                element.selected = false;
            });
            this.refreshCheck++;

        },

        cancelAssignment(item = null){

            this.alertClass.openAlertConfirmation(this.trans('confirm-message',180)).then(res => {

                if(res == true){
                    
                    this.cLoading = true;

                    let post_data = {
                        ids_assignment_data_main: null,
                        filters: JSON.stringify(this.filters),
                        return_type: "offers_list"
                    };

                    if(item == null){//multi
                        let userRole = this.user_role;
                        let ids_assignment_data_main = this.items.list.filter(
                                                                    function(value) {
                                                                                        return value.selected == true && ( userRole < 3 || value.can_user_edit) && ![6,3,5,7].includes(value.id_assignment_const_status); 
                                                                                    }
                                                                    ).map(a => a.id);


                        post_data.ids_assignment_data_main = JSON.stringify(ids_assignment_data_main);
                        
                    }else{//single
                        post_data.ids_assignment_data_main = JSON.stringify([item.id]);
                    }

                    axios
                        .post("planning/assignments/cancel", post_data)
                        .then((res) => {                 
                            this.filters.page = res.data.page;
                            this.items = res.data.items;
                        })
                        .catch((error) => {
                            console.error(`error during request: ${error}`);
                        })
                        .finally(() => {
                            this.unselect();
                            this.cTotal = this.items.total;
                            this.cLoading = false;
                        });


                } 

            });

        },

        addPerson(item = null) {

            let ids_assignment_data_main = [];

            let count_selected = this.items.list.filter(item => {return item.selected == true}).length;

            var userRole = this.user_role;
            
            if(count_selected > 0 && item == null){
                 let selected_all = this.items.list.filter(function(value) { 
                        return value.selected == true; 
                    }).length;

                 if(this.checked_items != selected_all){
                      this.alertClass.openAlertConfirmation(this.trans('as-add-people-to-assignments',216,{checked_items : this.checked_items})).then(res => {
                        if(res){
                            ids_assignment_data_main = this.items.list.filter(function(value) {
                                return value.selected == true && [1, 8].includes(value.id_assignment_const_status) && (userRole < 3 || value.can_user_edit)}).map(a => a.id);

                            let dates = this.items.list.filter(function(value) {
                                                    return value.selected == true && [1, 8].includes(value.id_assignment_const_status); }).map(a => a.date_from);
                                                    
                            var min_date = dates.reduce(function (a, b) { return moment(a, 'DD-MM-YYYY') < moment(b, 'DD-MM-YYYY') ? a : b; });

                            this.$router.push({ name: "planning-assignments-add-person", params: { ids_assignment_data_main: ids_assignment_data_main, start_date: min_date }});
                        }
                      });
                 }
                else{
                    ids_assignment_data_main = this.items.list.filter(function(value) {
                                                    return value.selected == true && [1, 8].includes(value.id_assignment_const_status) && (userRole < 3 || value.can_user_edit)}).map(a => a.id);

                    let dates = this.items.list.filter(function(value) {
                                                    return value.selected == true && [1, 8].includes(value.id_assignment_const_status); }).map(a => a.date_from);
                                                    
                    var min_date = dates.reduce(function (a, b) { return moment(a, 'DD-MM-YYYY') < moment(b, 'DD-MM-YYYY') ? a : b; });

                    this.$router.push({ name: "planning-assignments-add-person", params: { ids_assignment_data_main: ids_assignment_data_main, start_date: min_date }});
                }
               
            }else if(item != null){
                ids_assignment_data_main = [item.id];

                this.$router.push({ name: "planning-assignments-add-person", params: { ids_assignment_data_main: ids_assignment_data_main, start_date: item.date_from }});
            }

            
        },

        downloadList(){
            this.loading_download = true;
            axios
                .post("planning/offers/downloadList", {
                    filters: JSON.stringify(this.filters),
                },
                {
                    responseType: 'blob', 
                    crossDomain: true,
                })
                .then((res) => {                 
                    const url = window.URL.createObjectURL(new Blob([res.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'Detacheringen ' + moment().format('DD-MM-YYYY - HHmmss') + '.xlsx');
                    document.body.appendChild(link);
                    link.click();
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                })
                .finally(() => {
                    this.unselect();
                    this.loading_download = false;
                });
        },
        
        saveSettings() {
            this.userSettings.saveSettings(this.filters, 6);
        },

        async loadSettings() {
            if(this.listTypeChanged == false){
                var getData = JSON.parse(localStorage.getItem('userSettings'));

                if(getData == undefined || getData.shiftAssignmentList == undefined){
                    await this.userSettings.saveSettings(this.filters, 6);
                    getData = JSON.parse(localStorage.getItem('userSettings'));
                }

                var sFilters = getData.shiftAssignmentList;
                this.filters.filtering = sFilters.filtering;
                this.filters.sorting = sFilters.sorting;
                this.filters.per_page = sFilters.per_page != undefined ? sFilters.per_page : 10;  
                this.cListTypeChanged = false;
            }

            this.saveSettings();
        },

        onCreateThis() { 
            this.loadSettings();
            this.getList();

            this.$root.$on('locationsChanged', () => {
        
                if (!this.cLoading) {
                    this.getList();
                } 
            });

            this.$watch('offerDate', function(newVal, oldVal) {
                if (!this.cLoading && this.filters.date_type == 1) {   
                    this.filters.offer_date = this.offerDate;  
                    this.filters.offer_week = this.offerWeek; 
                    this.filters.date_type = this.dateType;                                  
                    this.getList();
                    this.saveSettings();
                } 
            });

            this.$watch('offerWeek', function(newVal, oldVal) {
                if (!this.cLoading && this.filters.date_type == 2) {
                    this.filters.offer_week = this.offerWeek; 
                    this.filters.offer_date = this.offerDate;  
                    this.filters.date_type = this.dateType;         
                    this.getList();
                    this.saveSettings();
                } 
            });

            this.$watch('dateType', function(newVal, oldVal) {
                if (!this.cLoading) {
                    this.filters.date_type = this.dateType;  
                    this.filters.offer_week = this.offerWeek;  
                    this.filters.offer_date = this.offerDate; 
                    this.getList();
                    this.saveSettings();
                } 
            });

            this.$watch('refresh', function(newVal, oldVal) {
                if (!this.cLoading) {
                    this.getList();
                } 
            });
        
            this.$watch('filters.list_type', function(newVal, oldVal) {
                if (!this.cLoading) {
                    this.saveSettings();
                    this.getList();
                } 
            });

            this.$watch('filters.sorting.changed', function(newVal, oldVal) {
                if (!this.cLoading) {
                    this.getList();
                    this.saveSettings();
                } 
            });

            this.$watch('filters.changed', function(newVal, oldVal) {
                if (!this.cLoading) {
                    this.getList();
                    this.saveSettings();
                } 
            });

            this.$watch('filters.date_type', function(newVal, oldVal) {
                this.saveSettings();
            });

             this.$watch('filters.filtering.client_names.changed', function(newVal, oldVal) {
                if (!this.cLoading) {
                    this.getList();
                    this.saveSettings();
                } 
            });

             this.$watch('filters.filtering.client_locations.changed', function(newVal, oldVal) {
                if (!this.cLoading) {
                    this.getList();
                    this.saveSettings();
                } 
            });

            this.$watch('filters.filtering.client_departments.changed', function(newVal, oldVal) {
                if (!this.cLoading) {
                    this.getList();
                    this.saveSettings();
                } 
            });

            this.$watch('filters.filtering.functions.changed', function(newVal, oldVal) {
                if (!this.cLoading) {
                    this.getList();
                    this.saveSettings();
                } 
            });

            this.$watch('filters.filtering.locations.changed', function(newVal, oldVal) {
                if (!this.cLoading) {
                    this.getList();
                    this.saveSettings();
                } 
            });

            this.$watch('filters.filtering.assignment_statuses.changed', function(newVal, oldVal) {
                if (!this.cLoading) {
                    this.getList();
                    this.saveSettings();
                } 
            });  

            this.$watch('filters.filtering.persons.changed', function(newVal, oldVal) {
                if (!this.cLoading) {
                    this.getList();
                    this.saveSettings();
                } 
            });        

            this.$watch('items.refresh', function(newVal, oldVal) {
                if (newVal > 0 && !this.cLoading) {
                    this.getList();
                }   
            });

            this.$watch('filters.page', function(newVal, oldVal) {
                if (!this.cLoading) {
                    this.getList();
                }   
            });

            
        },

    },
  };
</script>
<style scoped>
.app-local-sticky {
    position: absolute !important;
    right: 0;
    top: auto;
}
.tooltip{
            opacity: 1 !important;
      }

</style>
