<template>
    <b-modal ref="modal-add" size="md" v-model="computedShow" no-close-on-backdrop :title="trans('sh-change-services',215)">
        <ValidationObserver ref="form" >
            <b-form @submit.prevent="onSubmit">
                
                <app-row-left-label :label="trans('date',182)" :slots="[2, 10]">
                    <template v-slot:col-1>
                        <app-check-box class="mt-1 ml-2" v-model="change_date_from" value="1" unchecked-value="0"/>
                    </template>
                    <template v-slot:col-2>
                        <app-date :disabled="change_date_from == 0" id="vi-date-from" v-model="cArgs.date_from" :validatorRules="change_date_from == 1 ? 'required|date-check' :''" validatorName="Datum" :validatorCustomMessage="{ 'disable-date-past': trans('sh-date-error-7',221) }" :disabledDatesPass="8"/>
                    </template>
                </app-row-left-label>

                 <app-row-left-label :label="trans('sh-start-and-end-time',219)" :slots="[2, 5, 5]">
                    <template v-slot:col-1>
                        <app-check-box class="mt-1 ml-2" v-model="change_hour_from" value="1" unchecked-value="0"/>
                    </template>
                    <template v-slot:col-2>
                       <app-time :disabled="change_hour_from == 0" id="vi-time-from" v-model="cArgs.hour_from" :validatorRules="change_hour_from == 1 ? 'required' :''" validatorName="Begin tijd"/>
                    </template>
                    <template v-slot:col-3>
                        <app-time :disabled="change_hour_from == 0" id="vi-time-to" v-model="cArgs.hour_to" :validatorRules="change_hour_from == 1 ? 'required' :''" validatorName="Eind tijd"/>
                    </template>
                </app-row-left-label>

                <app-row-left-label :label="trans('planning-service-type',209)" >
                     <app-radio-group  :disabled="change_hour_from == 0" v-model="cArgs.id_shift_const_daytime" validatorName="Soort dienst" :validatorRules="change_hour_from == 1 ? 'required' :''" :options="day_time" />
                </app-row-left-label>

                <app-divider-modal class="mt-2 mb-3" :isLine="true" />

                <app-row-left-label :label="trans('change',175)">
                        <app-check-box class="mt-1 ml-2" v-model="change_office" value="1" unchecked-value="0" :disabled="cArgs.id_assignment_data_main"/>
                </app-row-left-label>

                <app-row-left-label :label="trans('branch',182)" >
                    <app-select v-model="cArgs.location" :disable="change_office == 0" :validatorRules="change_office == 1 ? 'required' :''" validatorName="Vestiging" type="getUserLocations" :disabled="cArgs.id_assignment_data_main"/>
                </app-row-left-label>

                <app-row-left-label :label="trans('location',3)">
                    <app-select v-model="cArgs.client_location" type="getLocationsOffers" :refreshIndex.sync="refreshClientLocations" :args.sync="cArgs" validatorName="Locatie" :validatorRules="change_office == 1 ? 'required' :''" :disable="cArgs.location == null || change_office == 0"/>
                </app-row-left-label>

                <app-row-left-label :label="trans('departments',3)">
                    <app-select class="w-100" v-model="cArgs.department" type="getDepartments" :refreshIndex.sync="refreshDepartments" :args.sync="cArgs" validatorName="Afdeling" :validatorRules="change_office == 1 ? 'required' :''" :disable="cArgs.client_location == null || change_office == 0"/>
                </app-row-left-label> 

                <app-multi-select v-model="cArgs.ids_function_data_main" :label="trans('function',3)" :additionalLabel="trans('deviant-funtions',182)" @finishLoad="finishLoads" labelPosition="left" :addButtonLabel="trans('planning-add-function',209)" type="getDepartmentsFunctions" :validatorRules="change_office == 1 ? 'required' :''" validatorName="Functie" :validatorCustomMessage="{'required': trans('select-at-least-one-function',180)}" :args.sync="cArgs.department" :isAllOption="false" :refreshIndex.sync="refreshFunctions" :key="refresh_multi" :disabled="cArgs.department == null || cArgs.department.id_cao_data_main == null || (change_office == 0 && finishLoad == 1)"/>
                    
            </b-form>
        </ValidationObserver>
        <template #modal-footer>
            <app-button type="cancel" @click="closeModal">{{trans('cancel',175)}}</app-button>
            <app-button :disabled="!cChangeButton" type="primary" @click="change" :loading="loading">
                {{trans('save',175)}}
            </app-button>
        </template>
    </b-modal>
</template>

<script>
import axios from "@axios";
import { ValidationObserver } from "vee-validate";
import { datecheck } from "@validations";
import moment from "moment";
import ShiftsTypeClass from "@app/Planning/Shifts/Profile/PlanData/ShiftsTypeClass.js";

export default {
    components: {
        ValidationObserver,
    },

    props: ["show", "args", "return", "result"],

    computed: {
        computedShow: {
        get() { return this.show },
        set(value) { this.$emit('update:show', value) }, 
        },

        cArgs: {
            get() { return this.args },
            set(value) { this.$emit('update:args', value) }
        },

        cReturn: {
            get() { return this.return },
            set(value) { this.$emit('update:return', value) }
        },

        cChangeButton: {
            get() { 
                    return this.change_date_from == 1 || this.change_hour_from == 1 || this.change_office == 1 || this.change_office == 1 ? true : false; 
                },
        }
    },

    mounted(){
        this.id = this.args.ids_function_data_main.length > 0 ? this.args.ids_function_data_main[0] : -1;
    },

    watch: {

        "cArgs.hour_from": {
            handler: function() {
                this.cArgs.id_shift_const_daytime = this.shift_type_class.calculate(this.cArgs.hour_from, this.cArgs.hour_to)
            }
        },

        "cArgs.hour_to": {
            handler: function() {
               this.cArgs.id_shift_const_daytime = this.shift_type_class.calculate(this.cArgs.hour_from, this.cArgs.hour_to)
            }
        },

        "cArgs.location": {
            handler: function(val) {
              if (this.cArgs.location !== null && !this.loading) {
                    
                    this.cArgs.id_office_data_location = this.cArgs.location.value;
                   
                    if (this.cArgs.client_location  != null){
                        this.cArgs.client_location.name = "";
                        this.cArgs.client_location.value = null;
                        this.cArgs.client_location = null;
                    }
                    
                    if (this.cArgs.department  != null){
                        this.cArgs.department.name = "";
                        this.cArgs.department.value = null;
                        this.cArgs.department.id_cao_data_main = null;
                        this.cArgs.department.cao_source = null;
                        this.cArgs.department.zzp_preferences = null;
                        this.cArgs.department = null;
                    }

                    if (this.cArgs.ids_function_data_main.length > 0){
                        this.cArgs.ids_function_data_main = [];
                        this.functions_additional = [];
                        this.refresh_multi++;
                    }

                    this.refreshClientLocations++;
                }
            }
        },

        "cArgs.client_location": {
            handler: function(val) {
               
                if (this.cArgs.client_location !== null) {
                    this.cArgs.id_location_data_main = this.cArgs.client_location.value;

                    if (this.cArgs.department  != null){
                        this.cArgs.department.name = "";
                        this.cArgs.department.value = null;
                        this.cArgs.department = null;
                    }

                    if (this.cArgs.ids_function_data_main.length > 0){
                        this.cArgs.ids_function_data_main = [];
                        this.functions_additional = [];
                        this.refresh_multi++;
                    }

                    this.refreshDepartments++;

                }
            },   
            deep: true,     
        },

        "cArgs.department": {
            handler: function(val) {
               
                if (this.cArgs.department !== null  && !this.loading) {

                    if (this.cArgs.ids_function_data_main.length > 0){
                        this.cArgs.ids_function_data_main = [];
                        this.functions_additional = [];
                        this.refresh_multi++;
                    }
                        this.refresh_multi++;
                }
            }         
        },

        "cArgs.ids_function_data_main": {
            handler: function(newVal, oldVal) {
                    if(this.id != this.cArgs.ids_function_data_main[0]){
                        this.addAdditionals();
                        this.id = this.cArgs.ids_function_data_main[0];
                    }
            },
            deep: true,
        },

    },

    data() {
        return {
            loading: false,
            change_date_from: 0,
            change_hour_from: 0,
            change_office: 0,
            shift_type_class :new ShiftsTypeClass(),
            day_time: [
                { value: 1, text: this.trans('day-part-morning',182) },
                { value: 2, text: this.trans('day-part-afternoon',182) },
                { value: 3, text: this.trans('day-part-day',182) },
                { value: 4, text: this.trans('day-part-evening',182) },
                { value: 5, text: this.trans('day-part-night',182) },
                { value: 6, text: this.trans('sleeping-service',182) }
            ],
            refreshClientLocations: 0,
            refreshDepartments: 0,
            refreshFunctions: 0,
            id: -1,
            functions_additional: [],  
            finishLoad: 0,
            refresh_multi: 0,
        };
    },

    methods: {
        finishLoads() {
            this.finishLoad = 1;
        },

        change() {            
          
            this.$refs.form.validate().then((result) => {
                if (!result) { 
                    return false;
                }
                this.loading = true;

                axios
                    .post("planning/offers/changeShifts", {
                        ids_shift_data_main: JSON.stringify(this.args.ids_shift_data_main),

                        date_from: this.cArgs.date_from,
                        hour_from: this.cArgs.hour_from,
                        hour_to: this.cArgs.hour_to,

                        id_office_data_location: this.cArgs.location ? this.cArgs.location.value : null,
                        id_location_data_main: this.cArgs.client_location ? this.cArgs.client_location.value : null,
                        id_department_data_main: this.cArgs.department ? this.cArgs.department.value : null,
                        id_cao_data_main: this.cArgs.department ? this.cArgs.department.id_cao_data_main : null,
                        type: this.cArgs.type,
                        id_shift_const_daytime: this.cArgs.id_shift_const_daytime,
                        ids_function_data_main: this.cArgs.ids_function_data_main,

                        change_date_from: this.change_date_from,
                        change_hour_from: this.change_hour_from,
                        change_office: this.change_office,
                    })
                    .then((res) => {
                        this.$emit("update:show", false);
                        this.$emit("update:result", 1);                        
                    })
                    .catch((error) => {
                        console.error(`error during request: ${error}`);
                    }).finally(() => {
                        this.loading = false;
                    });
                })
        },

        addAdditionals(){
            if(this.cArgs.department != null){
                var additional_functions_array = JSON.parse(this.cArgs.department.additional_functions)
                this.functions_additional = additional_functions_array;

                var functions = [];
                
                if(this.cArgs.ids_function_data_main[0] != null){
                    this.cArgs.ids_function_data_main = [this.cArgs.ids_function_data_main[0]]
                }
                if(this.functions_additional == null){
                    this.functions_additional = [];
                    return
                }

                for(var i=0; i<this.functions_additional.length; i++){
                    if(this.cArgs.ids_function_data_main.includes(this.functions_additional[i].id_function_data_main)){
                        functions = this.cArgs.ids_function_data_main.concat(this.functions_additional[i].ids_function_data_additional);
                        this.cArgs.ids_function_data_main = functions;
                        functions = [];
                        break;
                    }else if(this.cArgs.ids_function_data_main[0] != null){
                        this.cArgs.ids_function_data_main = [this.cArgs.ids_function_data_main[0]]
                    }
                }
                this.refresh_multi++;
            }
        },

        closeModal() {
            this.$emit("update:show", false);
        },
    },
};
</script>
