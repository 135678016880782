 <template>
     <b-card>
        <b-overlay :show="loading" :opacity="0.6">
            <b-row class="app-card-header">
                
                <b-col start>
                    <div class="app-card-tittle app-local-title-height">
                        <div> {{ type == 1 ? trans('services',3) : trans('posting',3)}} <span v-if="!loading">({{ total }})</span></div>
                    </div>
                </b-col>

                <b-col cols="2" v-if="type == 1" class="pr-0">
                    <app-select v-model="group_by" :options="group_options" reduceValue clearable/>
                </b-col>

                <b-col cols="auto" end>
                    <app-date-week-selector :choosedDate.sync="offer_date" :listType.sync="date_type" :changeArrows="true" :weekDates.sync="offer_week" :key="refresh" :clearable="true"/>
                </b-col>
                
                <b-col cols="auto" class="pl-0" end>
                    <b-button-group  size="sm">
                        <b-btn class="app-input-min-height app-list-button" v-bind:class="{ 'btn-secondary-grey': type !== 1, 'app-list-button-selected':  type === 1 }" @click="setListType(1)">{{trans('services',3)}}</b-btn>
                        <b-btn class="app-input-min-height app-list-button" v-bind:class="{ 'btn-secondary-grey': type !== 2, 'app-list-button-selected':  type === 2 }" @click="setListType(2)">{{trans('posting',3)}}</b-btn>
                    </b-button-group>                             
                </b-col>
 
                <b-col cols="auto" end class="d-flex align-items-center pl-0">
                    <app-refresh :refresh.sync="refresh" :loading.sync="loading"/>
                </b-col>
            </b-row>

            <offers-shifts-list-component v-if="type == 1" :loading.sync="loading" :offerDate.sync="offer_date" :offerWeek.sync="offer_week" :dateType.sync="date_type" :total.sync="total" :refresh.sync="refresh" :listType.sync="type" :listTypeChanged="list_type_changed" :groupBy.sync="group_by"/>
            <offers-assignments-list-component v-if="type == 2" :loading.sync="loading" :offerDate.sync="offer_date" :offerWeek.sync="offer_week" :dateType.sync="date_type" :total.sync="total" :refresh.sync="refresh" :listType.sync="type" :listTypeChanged="list_type_changed"/>
             <template #overlay>
                <div></div>
            </template>
        </b-overlay>            
    </b-card>
</template>

<script>

import moment from "moment";
import OffersShiftsListComponent from './Shifts/OffersShiftsListComponent.vue';
import OffersAssignmentsListComponent from './Assignments/OffersAssignmentsListComponent.vue';

export default {
  components: { 
      OffersShiftsListComponent,
      OffersAssignmentsListComponent,
   },

    props:["profile"],

    watch:{
        offer_date: {
            handler: function(val){
                if(val != null && this.date_type == 1){
                    this.offer_week = moment(val, 'DD-MM-YYYY').format('YYYY-W');
                } else if(this.date_type == 1){
                    this.offer_week = null;
                }
            }
        },
        offer_week: {
            handler: function(val){
                if(val != null && this.date_type == 2){
                    this.offer_date = moment(val, 'YYYY-W').format('DD-MM-YYYY');
                } else if(this.date_type == 2){
                    this.offer_date = null;
                }
            }
        },

        date_type: {
            handler: function(val){
                if(this.offer_week != null && this.date_type == 2){
                    this.offer_date = moment(this.offer_week, 'YYYY-W').format('DD-MM-YYYY');
                } else if(this.offer_date != null && this.date_type == 1){
                    this.offer_week = moment(this.offer_date, 'DD-MM-YYYY').format('YYYY-W');
                }
            }
        }
    },
    created(){
        this.getListType();
    },

    data(){
        return {
            loading: false,
            type: 1,
            total: 0,
            offer_date: null,
            offer_week: null,
            date_type: 1,            
            refresh:0,
            list_type_changed: false,
            group_options: [
                {name: this.trans('organization', 169), value: 'client_name'},
                {name: this.trans('location', 3), value: 'client_location_name'},
                {name: this.trans('department', 182), value: 'client_department_name'},
                {name: this.trans('function', 3), value: 'function_name'},
                {name: this.trans('branch', 182), value: 'location_name'},
            ],
            group_by: null,
        }
    },

    methods: {
        setListType(type) {
            this.type = type;
            this.list_type_changed = true;
        },

        getListType(){
           var getData = JSON.parse(localStorage.getItem('userSettings'));

            if(getData != null && getData.shiftAssignmentList != null){
                this.type = getData.shiftAssignmentList.list_type;
                this.date_type = getData.shiftAssignmentList.date_type;
                this.offer_date = getData.shiftAssignmentList.offer_date;
                this.offer_week = getData.shiftAssignmentList.offer_week;
                this.refresh++;
            }
        }
    }

};
</script>

<style scoped>

</style>