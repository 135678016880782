<template>
    <div>
        <app-view-table groupped :isTopBar="false" :isRowHover="true" :filters.sync="filters" :items="items" v-on:onRowSelected="goToShiftProfile" :fields="fields" :customColumns="customColumns" :customColumnsHeaders="customHeaders" class="table-selectable">

            <template v-slot:head_selected>
                <div @click.stop class="h-100 d-flex align-items-center justify-content-center">
                    <b-form-group class="pl-2">
                        <b-form-checkbox v-model="selected_all_rows" class="custom-control-primary" @change="selectAll()" style="margin-top:5px" size="md" :class="selected_all_rows ? 'custom-checkbox-checked' : ''"/>
                    </b-form-group>
                </div>
            </template>

            <template v-slot:selected="cell">
                <div @click.stop class="h-100 d-flex align-items-center justify-content-center">
                    <b-form-group class="pl-2">
                        <b-form-checkbox v-model="cell.data.item.selected" :key="refreshCheck" @change="select($event)" class="custom-control-primary" style="margin-top:5px" size="md" :class="cell.data.item.selected ? 'custom-checkbox-checked' : ''"/>
                    </b-form-group>
                </div>
            </template>

            <template v-slot:location_name="cell">
                <b-row v-if="cell.data.item.is_public == 0">
                    <b-col class="app-local-width pr-0">
                        <span class="app-local-ellips">{{cell.data.item.location_name}} </span>
                    </b-col>
                    <b-col cols="auto" class="mx-0 px-1">
                        <b-icon v-if="cell.data.item.is_public == 0" icon="lock-fill"></b-icon>
                    </b-col>
                </b-row>
                <span v-else class="app-local-ellips">{{cell.data.item.location_name}} </span>
            </template>
      
            <template v-slot:menu-items="cell">
                
                <b-dropdown-item v-if="[1, 3, 11].includes(cell.data.item.id_shift_const_status) && (user_role < 3 || cell.data.item.can_user_edit)" @click="addPerson(cell.data.item)">{{trans('cp-register-flexworker',189)}}</b-dropdown-item>    

                <b-dropdown-item v-if="cell.data.item.id_shift_ref_applicants != null && [4].includes(cell.data.item.id_shift_const_status) && (user_role < 3 || cell.data.item.can_user_edit)" @click="rejectPerson(cell.data.item, null)"> {{trans('sh-rejected-by-client',220)}} </b-dropdown-item>  
                <b-dropdown-item v-if="cell.data.item.id_shift_ref_applicants != null && [5].includes(cell.data.item.id_shift_const_status) && differenceDays(cell.data.item.db_shift_date_from) > -4  && (user_role < 3 || cell.data.item.can_user_edit)" @click="rejectPerson(cell.data.item, 7)">{{trans('sh-unsubscribe-flexworker',220)}}</b-dropdown-item> <!-- jezeli mniej 4 dni do rozpoczecia zmiana status na 7 -->
                <b-dropdown-item v-if="cell.data.item.id_shift_ref_applicants != null && [5].includes(cell.data.item.id_shift_const_status) && differenceDays(cell.data.item.db_shift_date_from) <= -4 && (user_role < 3 || cell.data.item.can_user_edit)" @click="rejectPerson(cell.data.item, 11)">{{trans('sh-replacement',220)}}</b-dropdown-item> <!-- jezeli jest wiecej niz 4 dni do rozpoczenia to reject fukcja-->
                <b-dropdown-item v-if="cell.data.item.id_shift_ref_applicants != null && [15].includes(cell.data.item.id_shift_const_status) && (user_role < 3 || cell.data.item.can_user_edit)" @click="rejectPerson(cell.data.item, null)"> {{trans('sh-rejected-by-flexworker',220)}} </b-dropdown-item>  

                <b-dropdown-item v-if="[1, 3, 11].includes(cell.data.item.id_shift_const_status) && (user_role < 3 || cell.data.item.can_user_edit)" @click="changeShift(cell.data.item)">{{trans('change',175)}}</b-dropdown-item>
                <b-dropdown-item v-if="(user_role < 3 || cell.data.item.can_user_edit)" :to="{ name: 'planning-offers-shifts-add-copy', params: { id_shift_data_main: cell.data.item.id}}">{{trans('sh-copy',220)}}</b-dropdown-item>
                <b-dropdown-item v-if="user_role == 1 && [1, 3, 11].includes(cell.data.item.id_shift_const_status)" :to="{ name: 'planning-offers-shifts-send-notifications', params: { id_shift_data_main: cell.data.item.id, shift_date: cell.data.item.date, shift_time_from: cell.data.item.hour_from, shift_time_to: cell.data.item.hour_to, client_name: cell.data.item.client_name, department_name: cell.data.item.client_department_name}}">{{trans('planning-send-notifications',209)}}</b-dropdown-item>
                <b-dropdown-divider v-if="(user_role < 3 || cell.data.item.can_user_edit) && ![2,7,8,10,13,16,17].includes(cell.data.item.id_shift_const_status) && (user_role < 3 || cell.data.item.can_user_edit)"></b-dropdown-divider>

                <b-dropdown-item v-if="![2,7,8,10,13,16,17].includes(cell.data.item.id_shift_const_status) && (user_role < 3 || cell.data.item.can_user_edit)" @click="cancelShift(13, cell.data.item)">{{trans('sh-service-withdrawn-by-customer',220)}}</b-dropdown-item>
                <b-dropdown-item v-if="![2,5,7,8,10,13,16,17].includes(cell.data.item.id_shift_const_status) && (user_role < 3 || cell.data.item.can_user_edit)" @click="cancelShift(2, cell.data.item)">{{trans('sh-service-lost-to-competitor',220)}}</b-dropdown-item>
                <b-dropdown-item v-if="![2,7,8,10,13,16,17].includes(cell.data.item.id_shift_const_status) && (user_role < 3 || cell.data.item.can_user_edit)" @click="cancelShift(17, cell.data.item)">{{trans('sh-remove-service',220)}}</b-dropdown-item>

                <b-dropdown-divider v-if="(user_role < 3 || cell.data.item.can_user_edit) || ![2,7,8,10,13,16,17].includes(cell.data.item.id_shift_const_status) && (user_role < 3 || cell.data.item.can_user_edit)"></b-dropdown-divider>
                <b-dropdown-item v-if="cell.data.item.id_person_data_main || cell.data.item.id_shift_const_status == 4" :to="{ name: 'people-person-profile', params: { id_person_data_main: cell.data.item.id_person_data_main }}"> {{trans('to-flexworker',175)}}</b-dropdown-item>
                <b-dropdown-item :to="{ name: 'clients-profile-organization', params: { id_client_data_main: cell.data.item.id_client_data_main }}" >{{trans('to-organization',175)}}</b-dropdown-item>
                <b-dropdown-item :to="{ name: 'clients-profile-location', params: { id_location_data_main: cell.data.item.id_location_data_main }}">{{trans('to-location',200)}}</b-dropdown-item>
                <b-dropdown-item :to="{ name: 'clients-profile-department', params: { id_department_data_main: cell.data.item.id_department_data_main }}">{{trans('to-department',200)}}</b-dropdown-item>
                                
            </template>

            <template v-slot:buttons>
                 
                 <b-dropdown v-if="cheked_items > 0" no-caret toggle-class="app-button-multi mr-2" variant="secondary-grey">
                    <template #button-content>
                         {{trans('action',3)}} ({{cheked_items}})                         
                    </template>
                    
                    <b-dropdown-item v-if="multi_menu_add_person_visible" @click="addPerson()">{{trans('cp-register-flexworker',189)}}</b-dropdown-item>    
                    <b-dropdown-item v-if="multi_menu_change_visible" @click="changeShift(null)">{{trans('change',175)}}</b-dropdown-item>                             
                    <b-dropdown-divider v-if="multi_menu_cancel && (multi_menu_add_person_visible || multi_menu_change_visible)"></b-dropdown-divider> 

                    <b-dropdown-item v-if="multi_menu_cancel" @click="cancelShift(13, null)">{{trans('sh-service-withdrawn-by-customer',220)}}</b-dropdown-item>
                    <b-dropdown-item v-if="multi_menu_cancel_extended" @click="cancelShift(2, null)">{{trans('sh-service-lost-to-competitor',220)}}</b-dropdown-item>
                    <b-dropdown-item v-if="multi_menu_cancel" @click="cancelShift(17, null)">{{trans('sh-remove-service',220)}}</b-dropdown-item>
                
                </b-dropdown>
                
                <app-button type="primary" :url="{ name: 'planning-offers-shifts-add'}" >{{trans('planning-add-service',210)}}</app-button>
                <app-button type="primary" :url="{ name: 'planning-offers-shifts-add-bulk'}">{{trans('planning-add-bulk-service',210)}}</app-button>
                <app-button type="primary" :loading="loading_download" @click="downloadList">{{trans('planning-export-shifts', 220)}}</app-button>
            </template>

        </app-view-table>

        <offers-shifts-list-change v-if="mShiftChange.show" :show.sync="mShiftChange.show" :result.sync="mShiftChange.result" :args="mShiftChange.args" :return.sync="items" />
       
    </div>  
</template>
<script>
 
// #ID _03-02.00 - PMI 2022-01-26

import axios from "@axios";
import AppAlerts from '@core/scripts/AppAlerts';
import UserSettings from '@core/scripts/UserSettings';
import moment from "moment";
import OffersShiftsListChange from './OffersShiftsListChange.vue';

  export default {

    props: ["loading", "offerDate", "offerWeek", "dateType", "total", "refresh", "listType", "listTypeChanged", "groupBy"],
    
    components: {
        OffersShiftsListChange,
    },

    mounted() {
      this.onCreateThis();
    },

    computed: {
        cOfferDate: {
            get() { return this.offerDate },
            set(value) {this.$emit('update:offerDate', value) }
        },
        cOfferWeek: {
            get() { return this.offerWeek },
            set(value) {this.$emit('update:offerWeek', value) }
        },
        cDateType: {
            get() { return this.dateType },
            set(value) {this.$emit('update:dateType', value) }
        },
        cRefresh: {
            get() { return this.refresh },
            set(value) {this.$emit('update:refresh', value) }
        },
        cLoading: {
            get() { return this.loading },
            set(value) {this.$emit('update:loading', value) }
        },
        cListType: {
            get() { return this.listType },
            set(value) {this.$emit('update:listType', value) }
        },
        cListTypeChanged: {
            get() { return this.listTypeChanged },
            set(value) {this.$emit('update:listTypeChanged', value) }
        },
        
        cTotal: {
            get() { return this.total },
            set(value) {this.$emit('update:total', value) }
        },

        cSelectedRows: {
            get() { 
                    if (this.items != undefined && this.items.list != undefined) {
                        let selected_all = this.items.list.filter(function(value) { 
                            return value.selected == true; 
                        }).length;

                        return selected_all; 
                    }
                    
                    return 0;
                }
        },
    },

    data() {
        return {
            customColumns: ["selected", "user_name", "location_name"],
            customHeaders: ['selected'],
            filters: {
                sorting: {
                        field_name: null,
                        sort_direction: 0,
                        changed: 0
                },
                filtering: {             
                    client_names: { type: 'list', changed: 0, values: [], function: "getClients", parameters: null, widthClass:'app-width-min-350'},           
                    client_locations: { type: 'list', changed: 0, values: [], function: "getClientLocations", parameters: null, widthClass:'app-width-min-350'},
                    client_departments: { type: 'list', changed: 0, values: [], function: "getClientDepartments", parameters: null, widthClass:'app-width-min-350'},
                    functions: { type: 'list', changed: 0, values: [], function: "getFunctions", parameters: null, widthClass:'app-width-min-350'},
                    locations: { type: 'list', changed: 0, values: [], function: "getLocations", parameters: null, widthClass:'app-width-min-350'},
                    dates: { type: 'dates', changed: 0, values: [],  function: "", parameters: {type: null}, widthClass:'app-width-min-350'},                   
                    shift_statuses: { type: 'list', changed: 0, values: [], function: "getShiftStatuses", parameters: null, widthClass:'app-width-min-350'},
                    daytime_name: { type: 'list', changed: 0, values: [], function: "getShiftDaytime", parameters: null, widthClass:'app-width-min-350'},
                    assignment_statuses: { type: 'list', changed: 0, values: [], function: "getAssignmentStatuses", parameters: null, widthClass:'app-width-min-350'},
                    persons: {type: 'list', changed: 0, values: [], function: 'getFlexworkers', parameters: null, widthClass:'app-width-min-250'}

                },
                page: 1,
                per_page: 10,
                search: "",                
                changed: 0,
                list_type: this.listType,
                date_type: this.dateType,
                offer_date: this.offerDate,
                offer_week: this.offerWeek,
                group_by: this.groupBy,

            },
            fields: [
                { key: "selected", label: "",tdClass:"p-0", thClass:"p-0", visible: true  },
                { key: "client_name", label: this.trans('organization',169), thStyle: { "min-width": "150px", "max-width": "150px" }, visible: true, filtering: true, filtering_name: "client_names"},
                { key: "client_location_name", label: this.trans('location',3), thStyle: { "min-width": "150px", "max-width": "150px" }, visible: true, filtering: true, filtering_name: "client_locations"},
                { key: "client_department_name", label: this.trans('department',182), thStyle: { "width": "100%" }, visible: true, filtering: true, filtering_name: "client_departments"},
                { key: "function_name", label: this.trans('function',3), thStyle: { "min-width": "150px", "max-width": "150px" }, visible: true, filtering: true, filtering_name: "functions"},
                { key: "location_name", label: this.trans('branch',182), thStyle: { "min-width": "150px", "max-width": "150px" }, visible: true, filtering: true, filtering_name: "locations"},
                { key: "date", label: this.trans('date',182), thStyle: { "min-width": "100px", "max-width": "100px" }, visible: true, filtering: true, filtering_name: "dates"},
                { key: "hour_from", label: this.trans('planning-begin',209), thStyle: { "min-width": "80px", "max-width": "80px" }, visible: true, filtering: false},
                { key: "hour_to", label: this.trans('planning-end',209), thStyle: { "min-width": "80px", "max-width": "80px" }, visible: true, filtering: false},
                { key: "daytime_name", label: this.trans('planning-service-type',209), thStyle: { "min-width": "80px", "max-width": "80px" }, visible: true, filtering: true, filtering_name: "daytime_name"},
                { key: "person_name", label: this.trans('flexworker',182), thStyle: { "min-width": "150px", "max-width": "150px"}, visible: true, filtering: true, filtering_name: "persons"},             
                { key: "status_name", label: this.trans('status',3), thStyle: { "min-width": "120px", "max-width": "120px" }, visible: true, filtering: true, filtering_name: "shift_statuses"},
            ],
            items: [],
            selected_all_rows: false,
            alertClass: new AppAlerts(),
            userSettings: new UserSettings(),
            multi_menu_add_person_visible: false,
            multi_menu_change_visible: false,
            multi_menu_cancel: false,
            multi_menu_cancel_extended: false,
            refreshCheck: 0,
            cheked_items: 0,

            mShiftChange: { show: false, args: {
                ids_shift_data_main: [],
                date_from: null,
                hour_from: null,
                hour_to: null,
                id_cao_data_main: null,
                location:null,
                client_location:null,
                department:null,
                type: 'shift-list',
                filters: null,
                id_shift_const_daytime: null,
                id_location_data_main: null,
                id_office_data_location: null,
                id_assignment_data_main: null,
                ids_function_data_main: null,
            }, result: 0, return: null},
            user_role: JSON.parse(localStorage.getItem('user')).id_user_const_role,
            loading_download: false

        };
    },

    methods: {
        
        getList() {
           this.cLoading = true;
           axios
                .post("planning/offers/getShifts", {
                    filters: JSON.stringify(this.filters),
                })
                .then((res) => {                 
                    this.filters.page = res.data.page;
                    this.items = res.data.items;
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                })
                .finally(() => {
                    this.unselect();
                    this.cTotal = this.items.total;
                    this.cLoading = false;
                });
        },

        goToShiftProfile: function(selectedRows) {
            if(selectedRows.length != 0){
                this.$router.push({ name: "planning-shifts-profile", params: { id_shift_data_main: selectedRows[0].id } });
            }
        },
        
        changeShift (shift_row) {
            
            if (shift_row == null) { 
                var userRole = this.user_role;
                this.mShiftChange.args.ids_shift_data_main = this.items.list.filter(
                                                                                    function(value) {
                                                                                        return value.selected == true && [1, 3, 11].includes(value.id_shift_const_status) && ( userRole < 3 || value.can_user_edit);
                                                                                    }
                                                                                ).map(a => a.id);

                let ids_cao_data_main = this.items.list.filter(
                                                                function(value) {
                                                                    return value.selected == true && [1, 3, 11].includes(value.id_shift_const_status) && ( userRole < 3 || value.can_user_edit);
                                                                }
                                                            ).map(a => a.id_cao_data_main);

                // ids_cao_data_main = ids_cao_data_main.filter((x, y) => ids_cao_data_main.indexOf(x) == y);
                                                        
                if (ids_cao_data_main.length == 1) {
                    this.mShiftChange.args.id_cao_data_main = ids_cao_data_main[0];
                    var item = this.items.list.filter( function(value) { return value.selected == true && [1, 3, 11].includes(value.id_shift_const_status) && ( userRole < 3 || value.can_user_edit) } );
                    this.mShiftChange.args.date_from = item[0].date;
                    this.mShiftChange.args.hour_from = item[0].hour_from;
                    this.mShiftChange.args.hour_to = item[0].hour_to;
                    this.mShiftChange.args.id_cao_data_main = item[0].id_cao_data_main;
                    this.mShiftChange.args.id_shift_const_daytime = item[0].id_shift_const_daytime;
                    this.mShiftChange.args.ids_function_data_main = [item[0].id_function_data_main].concat(JSON.parse(item[0].function_additional));
                    this.mShiftChange.args.location = {value: item[0].id_office_data_location, name: item[0].location_name, users: item[0].location_users, student_recruitment: item[0].student_recruitment};
                    this.mShiftChange.args.id_location_data_main = item[0].id_location_data_main;
                    this.mShiftChange.args.department = {value: item[0].id_department_data_main, name: item[0].client_department_name, id_cao_data_main: item[0].id_cao_data_main, additional_functions: item[0].client_additional_functions, id_department_data_main: item[0].id_department_data_main, cao_source: item[0].cao_source, zzp_preferences: item[0].zzp_preferences};
                    this.mShiftChange.args.id_office_data_location = item[0].id_office_data_location;
                    this.mShiftChange.args.client_location = {value: item[0].id_location_data_main, name: item[0].client_location_names, id_client_data_main: item[0].id_client_data_main, import_procedure: item[0].import_procedure, registration_process: item[0].registration_process, registration_tool: item[0].registration_tool, client_confirmation: item[0].client_confirmation, send_notification: item[0].send_notification};
                    this.mShiftChange.args.id_assignment_data_main = item[0].id_assignment_data_main;
                } else {
                    this.mShiftChange.args.id_cao_data_main = null;
                    this.mShiftChange.args.date_from = null;
                    this.mShiftChange.args.hour_from = null;
                    this.mShiftChange.args.hour_to = null;
                    this.mShiftChange.args.id_shift_const_daytime = null;
                    this.mShiftChange.args.ids_function_data_main = [];
                    this.mShiftChange.args.location = null;
                    this.mShiftChange.args.id_location_data_main = null;
                    this.mShiftChange.args.department = null;
                    this.mShiftChange.args.id_office_data_location = null;
                    this.mShiftChange.args.client_location = null;
                    this.mShiftChange.args.id_assignment_data_main = null;
                }
             

            } else {
                this.mShiftChange.args.ids_shift_data_main[0] = shift_row.id;
                this.mShiftChange.args.date_from = shift_row.date;
                this.mShiftChange.args.hour_from = shift_row.hour_from;
                this.mShiftChange.args.hour_to = shift_row.hour_to;
                this.mShiftChange.args.id_cao_data_main = shift_row.id_cao_data_main;
                this.mShiftChange.args.id_shift_const_daytime = shift_row.id_shift_const_daytime;
                this.mShiftChange.args.ids_function_data_main = [shift_row.id_function_data_main].concat(JSON.parse(shift_row.function_additional));
                this.mShiftChange.args.location = {value: shift_row.id_office_data_location, name: shift_row.location_name, users: shift_row.location_users, student_recruitment: shift_row.student_recruitment};
                this.mShiftChange.args.id_location_data_main = shift_row.id_location_data_main;
                this.mShiftChange.args.department = {value: shift_row.id_department_data_main, name: shift_row.client_department_name, id_cao_data_main: shift_row.id_cao_data_main, additional_functions: shift_row.client_additional_functions, id_department_data_main: shift_row.id_department_data_main, cao_source: shift_row.cao_source, zzp_preferences: shift_row.zzp_preferences};
                this.mShiftChange.args.id_office_data_location = shift_row.id_office_data_location;
                this.mShiftChange.args.client_location = {value: shift_row.id_location_data_main, name: shift_row.client_location_names, id_client_data_main: shift_row.id_client_data_main, import_procedure: shift_row.import_procedure, registration_process: shift_row.registration_process, registration_tool: shift_row.registration_tool, client_confirmation: shift_row.client_confirmation, send_notification: shift_row.send_notification};
                this.mShiftChange.args.id_assignment_data_main = shift_row.id_assignment_data_main;
            }    

            this.mShiftChange.args.filters = this.filters;
            this.mShiftChange.result = 0;
            this.mShiftChange.show = true;        
            
        },
     
        

        selectAll(){
            
            if(this.selected_all_rows){
                this.items.list.forEach(element => {
                    if(!('_groupped' in element))
                    element.selected = true;
                });

                

            } else {
                this.items.list.forEach(element => {
                    element.selected = false;
                });
                this.cheked_items = 0;
            }

            this.select();

            this.refreshCheck++;

        },

        select(){
            if (this.items != undefined && this.items.list != undefined) {
                let selected = this.items.list.filter(function(value) { 
                    return value.selected == true; 
                }).length;

                if(this.items.list.length == selected){
                    this.selected_all_rows = true;
                }else{
                    this.selected_all_rows = false;
                }
                
                let userRole = this.user_role
                let selected_to_add = this.items.list.filter(function(value) { 
                    return value.selected == true && (userRole < 3 || value.can_user_edit) && ![2,7,8,10,13,16,17].includes(value.id_shift_const_status); 
                }).length;

                this.cheked_items = selected_to_add;

                this.multiMenuVisible();
            }
        },

        unselect(){

            this.selected_all_rows = false;
            this.items.list.forEach(element => {
                element.selected = false;
            });
            this.cheked_items = 0;
            this.refreshCheck++;

        },

        multiMenuVisible(){
            if (this.items != undefined && this.items.list != undefined) {
                var userRole = this.user_role;
                let selected_to_add = this.items.list.filter(function(value) { 
                    return value.selected == true && [1, 3, 11].includes(value.id_shift_const_status) && ( userRole < 3 || value.can_user_edit); 
                }).length;

                let selected_edit = this.items.list.filter(function(value) { 
                    return value.selected == true && [1, 3, 11].includes(value.id_shift_const_status) && ( userRole < 3 || value.can_user_edit); 
                }).length;

                let selected_cancel = this.items.list.filter(function(value) { 
                    return value.selected == true && ( userRole < 3 || value.can_user_edit) && ![2,7,8,10,13,16,17].includes(value.id_shift_const_status); 
                }).length;

                let selected_cancel_extended = this.items.list.filter(function(value) { 
                    return value.selected == true && ( userRole < 3 || value.can_user_edit) && ![2,5,7,8,10,13,16,17].includes(value.id_shift_const_status); 
                }).length;

                this.multi_menu_add_person_visible = selected_to_add > 0;
                this.multi_menu_change_visible = selected_edit == this.cheked_items;
                this.multi_menu_cancel = selected_cancel == this.cheked_items;
                this.multi_menu_cancel_extended = selected_cancel_extended == this.cheked_items;
            }else{
                this.multi_menu_add_person_visible = false;
                this.multi_menu_change_visible = false;
                this.multi_menu_cancel = false;
                this.multi_menu_cancel_extended = false;
            }
            
            if(this.multi_menu_cancel == false && this.multi_menu_add_person_visible == false && this.multi_menu_change_visible == false){
                this.cheked_items = 0;
            }
        },

        addPerson(item = null) {

            let ids_shift_data_main = [];
            let ids_function_data_main = [];
            let ids_office_data_location = [];
            let dates = [];
            var userRole = this.user_role;
            
            let count_selected = this.items.list.filter(item => {return item.selected == true}).length;
            if(count_selected > 0 && item == null){
                 let selected_all = this.items.list.filter(function(value) { 
                        return value.selected == true; 
                    }).length;

                 if(this.cheked_items != selected_all){
                      this.alertClass.openAlertConfirmation(this.trans('sh-add-people-to-shifts-confirmation',221,{cheked_items : this.cheked_items})).then(res => {
                        if(res){
                            ids_shift_data_main = this.items.list.filter(function(value) {
                                                    return value.selected == true && [1, 3, 11].includes(value.id_shift_const_status) && ( userRole < 3 || value.can_user_edit) }).map(a => a.id);

                            let dates = this.items.list.filter(function(value) {
                                                    return value.selected == true && [1, 3, 11].includes(value.id_shift_const_status) && ( userRole < 3 || value.can_user_edit) }).map(a => a.date);

                            var min_date = dates.reduce(function (a, b) { return moment(a, 'DD-MM-YYYY') < moment(b, 'DD-MM-YYYY') ? a : b; });
                    

                            this.$router.push({ name: "planning-shifts-add-person", params: { ids_shift_data_main: ids_shift_data_main, start_date: min_date, returnType: "offers_list" }});
                        }
                      });
                 }
                else{
                    ids_shift_data_main = this.items.list.filter(function(value) {
                                                    return value.selected == true && [1, 3, 11].includes(value.id_shift_const_status) && ( userRole < 3 || value.can_user_edit) }).map(a => a.id);

                    let dates = this.items.list.filter(function(value) {
                                                    return value.selected == true && [1, 3, 11].includes(value.id_shift_const_status) && ( userRole < 3 || value.can_user_edit) }).map(a => a.date);
                                                    
                    var min_date = dates.reduce(function (a, b) { return moment(a, 'DD-MM-YYYY') < moment(b, 'DD-MM-YYYY') ? a : b; });
                    

                    this.$router.push({ name: "planning-shifts-add-person", params: { ids_shift_data_main: ids_shift_data_main, start_date: min_date, returnType: "offers_list" }});
                }
               
            }else if(item != null){
                ids_shift_data_main = [item.id];
                this.$router.push({ name: "planning-shifts-add-person", params: { ids_shift_data_main: ids_shift_data_main, start_date: item.date, returnType: "offers_list" }});
            }

            
        },


        cancelShift(id_shift_const_status, item = null){

            this.alertClass.openAlertConfirmation(this.trans('confirm-message',180)).then(res => {

                if(res == true){
                    
                    this.cLoading = true;

                    let post_data = {
                        ids_shift_data_main: null,
                        id_shift_const_status: id_shift_const_status,
                        filters: JSON.stringify(this.filters),
                        return_type: "offers_list"
                    };

                    if(item == null){//multi
                        let userRole = this.user_role;
                        let ids_shift_data_main = this.items.list.filter(
                                                                    function(value) {
                                                                                        return value.selected == true && (userRole < 3 || value.can_user_edit) && ![2,7,8,10,13,16,17].includes(value.id_shift_const_status); 
                                                                                    }
                                                                    ).map(a => a.id);


                        post_data.ids_shift_data_main = JSON.stringify(ids_shift_data_main);
                        
                    }else{//single
                        post_data.ids_shift_data_main = JSON.stringify([item.id]);
                    }

                    axios
                        .post("planning/shifts/cancel", post_data)
                        .then((res) => {                 
                            this.filters.page = res.data.page;
                            this.items = res.data.items;
                        })
                        .catch((error) => {
                            console.error(`error during request: ${error}`);
                        })
                        .finally(() => {
                            this.unselect();
                            this.cTotal = this.items.total;
                            this.cLoading = false;
                        });


                } 

            });

        },

        rejectPerson(item, idShiftConstStatus){

            let alertMessage = "";
            let postData = null;

            if(item.id_shift_const_status == 5){//bevestigd
                alertMessage = this.trans('confirm-message',180);

                postData = {
                                id_shift_data_main: item.id,
                                ids_shift_ref_applicants: JSON.stringify([item.id_shift_ref_applicants]),
                                return_type: "offers_list",
                                id_shift_const_status: idShiftConstStatus,
                                filters: JSON.stringify(this.filters),
                            };
            }else{
                alertMessage = this.trans('sh-reject-applicant-confirmation',221, {personNameApplicant : item.person_name_alert});
                postData = {
                                id_shift_data_main: item.id,
                                ids_shift_ref_applicants: JSON.stringify([item.id_shift_ref_applicants]),
                                return_type: "offers_list",
                                filters: JSON.stringify(this.filters),
                            };
            }

            this.alertClass.openAlertConfirmation(alertMessage).then(res => {

                if(res == true){
                    this.cLoading = true;
                    
                    axios
                        .post('planning/shifts/person/reject', postData)
                        .then((res) => {
                            this.filters.page = res.data.page;
                            this.items = res.data.items;
                        })
                        .catch((error) => {
                            console.error(`error during request: ${error}`);
                        })
                        .finally(() => {
                            this.unselect();
                            this.cTotal = this.items.total;
                            this.cLoading = false;
                        });
                }

            });

        },

        differenceDays(date){
            var today = moment(moment.now());
            var d = today.diff(moment(date, 'YYYY-MM-DD HH:mm'), 'days');
            return d;
        },

        downloadList(){
            this.loading_download = true;
            axios
                .post("planning/offers/downloadList", {
                    filters: JSON.stringify(this.filters),
                },
                {
                    responseType: 'blob', 
                    crossDomain: true,
                })
                .then((res) => {                 
                    const url = window.URL.createObjectURL(new Blob([res.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'Diensten ' + moment().format('DD-MM-YYYY - HHmmss') +'.xlsx');
                    document.body.appendChild(link);
                    link.click();
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                })
                .finally(() => {
                    this.unselect();
                    this.loading_download = false;
                });
        },

        saveSettings() {
            this.userSettings.saveSettings(this.filters, 6);
        },

        async loadSettings() {
            if(this.listTypeChanged == false){
                var getData = JSON.parse(localStorage.getItem('userSettings'));

                if(getData == undefined || getData.shiftAssignmentList == undefined){
                    await this.userSettings.saveSettings(this.filters, 6);
                    getData = JSON.parse(localStorage.getItem('userSettings'));
                }

                var sFilters = getData.shiftAssignmentList;
                this.filters.filtering = sFilters.filtering;
                this.filters.sorting = sFilters.sorting;
                this.filters.per_page = sFilters.per_page != undefined ? sFilters.per_page : 10;  
                this.cListTypeChanged = false;
            }

            this.saveSettings();
        },

        onCreateThis() { 
            this.loadSettings();
            this.getList();
        
            this.$watch('offerDate', function(newVal, oldVal) {
                if (!this.cLoading && this.filters.date_type == 1) {   
                    this.filters.offer_date = this.offerDate; 
                    this.filters.offer_week = this.offerWeek;   
                    this.filters.date_type = this.dateType;  
                    this.getList();
                    this.saveSettings();
                } 
            });

            this.$watch('offerWeek', function(newVal, oldVal) {
                if (!this.cLoading && this.filters.date_type == 2) {
                    this.filters.offer_week = this.offerWeek;   
                    this.filters.offer_date = this.offerDate; 
                    this.filters.date_type = this.dateType;     
                    this.getList();
                    this.saveSettings();
                } 
            });

            this.$watch('groupBy', function(newVal, oldVal){
                if(!this.cLoading){
                    this.filters.group_by = this.groupBy;
                    this.getList();
                    // this.saveSettings();
                } 
            });

            this.$watch('filters.sorting.changed', function(newVal, oldVal) {
                if (!this.cLoading) {
                    this.getList();
                    this.saveSettings();
                } 
            });

            this.$watch('filters.changed', function(newVal, oldVal) {
                if (!this.cLoading) {
                    this.getList();
                    this.saveSettings();
                } 
            });

            this.$watch('filters.list_type', function(newVal, oldVal) {
                if (!this.cLoading) {
                    this.getList();
                    this.saveSettings();
                } 
            });

            this.$watch('dateType', function(newVal, oldVal) {
                if (!this.cLoading) {
                    this.filters.date_type = this.dateType;  
                    this.filters.offer_week = this.offerWeek;  
                    this.filters.offer_date = this.offerDate;  
                    this.getList();
                    this.saveSettings();
                } 
            });

            
            this.$watch('refresh', function(newVal, oldVal) {
                if (!this.cLoading) {
                    this.getList();
                } 
            });

            this.$watch('filters.filtering.client_names.changed', function(newVal, oldVal) {
                if (!this.cLoading) {
                    this.getList();
                    this.saveSettings();
                } 
            });

             this.$watch('filters.filtering.client_locations.changed', function(newVal, oldVal) {
                if (!this.cLoading) {
                    this.getList();
                    this.saveSettings();
                } 
            });

            this.$watch('filters.filtering.client_departments.changed', function(newVal, oldVal) {
                if (!this.cLoading) {
                    this.getList();
                    this.saveSettings();
                } 
            });

            this.$watch('filters.filtering.functions.changed', function(newVal, oldVal) {
                if (!this.cLoading) {
                    this.getList();
                    this.saveSettings();
                } 
            });

            this.$watch('filters.filtering.locations.changed', function(newVal, oldVal) {
                if (!this.cLoading) {
                    this.getList();
                    this.saveSettings();
                } 
            });

            this.$watch('filters.filtering.shift_statuses.changed', function(newVal, oldVal) {
                if (!this.cLoading) {
                    this.getList();
                    this.saveSettings();
                } 
            });

            this.$watch('filters.filtering.cao.changed', function(newVal, oldVal) {
                if (!this.cLoading) {
                    this.getList();
                    this.saveSettings();
                } 
            });       
            
            this.$watch('filters.filtering.daytime_name.changed', function(newVal, oldVal) {
                if (!this.cLoading) {
                    this.getList();
                    this.saveSettings();
                } 
            });   

            this.$watch('filters.filtering.persons.changed', function(newVal, oldVal) {
                if (!this.cLoading) {
                    this.getList();
                    this.saveSettings();
                } 
            });
            
            this.$watch('filters.filtering.dates.changed', function(newVal, oldVal) {
                if (!this.cLoading) {
                    this.getList();
                    this.saveSettings();
                } 
            });  
           
            this.$watch('filters.page', function(newVal, oldVal) {
                this.cheked_items = 0;
                if (!this.cLoading) {
                    this.getList();
                }   
            });

            this.$watch('mShiftChange.result', function(newVal, oldVal) {
                    if(this.mShiftChange.result == 1){
                        this.mShiftChange.result = 0;
                        this.cheked_items = 0;
                        this.getList();
                        this.unselect();
                    }
            });

            this.$watch('mPersonAdd.result', function(newVal, oldVal) {
                    if(this.mPersonAdd.result == 1){
                        this.mPersonAdd.result = 0;
                        this.unselect();
                    }
            });

        },

    },
  };
</script>
<style scoped>
.app-local-sticky {
    position: absolute !important;
    right: 0;
    top: auto;
}
.tooltip{
    opacity: 1 !important;
}
.app-local-ellips {
    display: block;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow:ellipsis; 
   }

.app-local-width{
    width: calc(100% - 32px);
}

</style>
